import React, {useMemo} from "react";
import {i18n} from "../../../Utils/i18n/i18n";
import './ImportUsersModal.scss'
import {buttonColors} from "../../Pickers/InputTheme";
import {Modal} from "../../Smart/Modal/Modal";
import {Button} from "../../Form/Button/Button";
import {LocalTabs} from "../../../Utils/RoutingTabs/Tabs";
import {ImportUsers} from "./ImportUsers";
import {ImportUsersReactContext} from "./ImportUsersModalContext";
import {get} from "lodash";

export const ImportUsersModal = ({importUserModalOpen, closeImportUsersModal, onSuccessCreateUsers, onlyImport}) => {
    const importUsersContext = React.useContext(ImportUsersReactContext)

    const uploadUsers = async () => {
        await importUsersContext.triggerUploadUsers()
        onSuccessCreateUsers && onSuccessCreateUsers()
    }
    let memoizedTabs = useMemo(() => {
        let tabs = [{
            label: i18n.translate('importUsersModal.tabs.importUsers.title'),
            value: 'importUsersOrgAdmin',
            content: <ImportUsers onlyAddOrgsWithoutRemoving={true}/>
        }]
        if (!onlyImport) {
            tabs.unshift({
                label: i18n.translate('importUsersModal.tabs.reorganizeUsers.title'),
                value: 'importUsersCompanyAdmin',
                onlyForCompanyAdmin: true,
                content: <ImportUsers onlyAddOrgsWithoutRemoving={false}/>
            })
        }
        return tabs

    }, [onlyImport]);


    return <Modal
        open={importUserModalOpen}
        onClose={closeImportUsersModal}>
        <div className="importUsersModal modalWrapper">
            <div className="modalTitle">
                <div>{i18n.translate('importUsersModal.title')}</div>
            </div>
            <div className="modalContent">
                {get(importUsersContext, 'usersToUploadData.users.length') > 400 ? <div className="manyUsersMessage">
                    {i18n.translate('importUsersModal.manyUsersMessage')}
                </div> : ""}
                <div className="tabsWrapper">
                    <LocalTabs tabs={memoizedTabs}/>
                </div>

            </div>
            <div className="modalFooter">
                <Button
                    disabled={importUsersContext.isUploading}
                    color={buttonColors.YELLOW}
                    onClick={closeImportUsersModal}
                    text={i18n.translate('general.close')}
                />
                <Button
                    disabled={importUsersContext.isSubmitDisabled() || importUsersContext.isUploading}
                    color={buttonColors.GREY}
                    onClick={uploadUsers}
                    text={i18n.translate('importUsersModal.createUsers')}
                />
            </div>
        </div>
    </Modal>
}