import {i18n} from "../../../../Utils/i18n/i18n";
import {get, map} from 'lodash'
import {DataGridWrapper} from "../../../../Utils/DataGridWrapper/DataGridWrapper";
import {SA_getAllCompanies, SA_getAllUsersFiltered} from "../../../../Services/SA_api";
import '../AllItemsPages.scss'
import '../../SuperAdminPage.scss'
import {SAEditUserCell} from "./SAEditUserCell/SAEditUserCell";
import {GridReactContextProvider} from "../../../../Utils/DataGridWrapper/GridContext";
import {useEffect, useState} from "react";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import {DeleteUsersTableButton} from "../../../DeleteUsersTableButton/DeleteUsersTableButton";
import {SARemoveUserCell} from "./SARemoveUserCell/SARemoveUserCell";
import {CopyText} from "../../../../Utils/CopyText/CopyText";
import {IsCompanyAdminCell} from "./IsCompanyAdminCell/IsCompanyAdminCell";

const getColumns = async () => {
    return [
        {
            field: 'firstName',
            headerName: i18n.translate("superAdmin.allUsersTable.columns.firstName"),
            minWidth: 100,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}>{params.value}</div>,
        },
        {
            field: 'lastName',
            headerName: i18n.translate("superAdmin.allUsersTable.columns.lastName"),
            minWidth: 100,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'identifier',
            headerName: i18n.translate("superAdmin.allUsersTable.columns.identifier"),
            minWidth: 140,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'companyName',
            headerName: i18n.translate("superAdmin.allUsersTable.columns.companyName"),
            minWidth: 140,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}
                                         title={get(params, 'row.company.companyName')}>{get(params, 'row.company.companyName')}</div>,
        },
        // {
        //     field: 'phone',
        //     headerName: i18n.translate("superAdmin.allUsersTable.columns.phone"),
        //     minWidth: 100,
        //     align: 'right',
        //     sortable: false,
        //     renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        // },
        // {
        //     field: 'email',
        //     headerName: i18n.translate("superAdmin.allUsersTable.columns.email"),
        //     minWidth: 140,
        //     align: 'right',
        //     sortable: false,
        //     renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        // },
        {
            field: 'loginUrl',
            headerName: i18n.translate("superAdmin.allUsersTable.columns.loginUrl"),
            minWidth: 140,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}><CopyText text={params.value}/></div>,
        },
        {
            field: 'id',
            headerName: i18n.translate("superAdmin.allUsersTable.columns.id"),
            minWidth: 140,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'isCompanyAdmin',
            headerName: i18n.translate("superAdmin.allUsersTable.columns.isCompanyAdmin"),
            minWidth: 180,
            align: 'right',
            sortable: false,
            renderCell: (params) => <IsCompanyAdminCell user={params.row}/>
        },
        {
            field: 'isSuperAdmin',
            headerName: i18n.translate("superAdmin.allUsersTable.columns.isSuperAdmin"),
            minWidth: 60,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>
                {get(params, 'row.roles.SuperAdmin') ? i18n.translate('superAdmin.allUsersTable.columns.yes') : i18n.translate('superAdmin.allUsersTable.columns.no')}
            </div>,
        },
        {
            field: 'deletedAt',
            headerName: i18n.translate("superAdmin.allUsersTable.columns.isDeleted"),
            minWidth: 60,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value ? i18n.translate('superAdmin.allUsersTable.columns.yes') : i18n.translate('superAdmin.allUsersTable.columns.no')}</div>,
        },
        {
            field: 'edit',
            headerName: i18n.translate("superAdmin.allUsersTable.columns.edit"),
            minWidth: 200,
            align: 'right',
            sortable: false,
            renderCell: (params) => <SAEditUserCell user={params.row}/>,
        },
        {
            field: 'delete',
            headerName: i18n.translate("superAdmin.allUsersTable.columns.permanentlyDelete"),
            minWidth: 180,
            align: 'right',
            sortable: false,
            renderCell: (params) => <SARemoveUserCell user={params.row}/>,
        }
    ];
}

const convertData = (users) => {
    return map(users, (user) => {
        return user
    })
}
const paginationModel = {
    page: 0,
    pageSize: 30,
};
const getFilterModel = ({companies}) => {
    return {
        filterValues: {},
        filterData: {
            companies
        },
        showFilters: {
            search: true,
            companies: true
        }
    }
}

export const SuperAdminAllUsers = ({}) => {
    const [selectionModel, setSelectionModel] = useState([])
    const [companies, setCompanies] = useState()
    const init = async () => {
        const companies = await SA_getAllCompanies()
        const selectBoxCompanies = [{
            value: "",
            text: ''
        }, ...map(companies, (company) => {
            return {
                value: get(company, 'id'),
                text: get(company, 'companyName')
            }
        })]
        // order by company name
        selectBoxCompanies.sort((a, b) => a.text.localeCompare(b.text))
        setCompanies(selectBoxCompanies)
    }
    useEffect(() => {
        init()
    }, []);
    const apiToGetData = async ({paginationModel, filterModel}) => {
        return SA_getAllUsersFiltered({paginationModel, filterModel})
    }
    const onSelectionModelChange = ({newSelectionModel}) => {
        setSelectionModel(newSelectionModel)
    }

    const onDeleteUsersSuccess = () => {

    }

    const buttons = [
        {
            type: 'ComponentButton',
            component: ({paginationModel, filterModel, sortModel, selectionModel}) =>
                <DeleteUsersTableButton
                    onSuccess={onDeleteUsersSuccess}
                    selectionModel={selectionModel}
                    paginationModel={paginationModel} filterModel={filterModel} sortModel={sortModel}/>
        }
    ]


    return <div className="allItemsWrapper SuperAdminPage">
        <GridReactContextProvider>
            {companies ? <DataGridWrapper
                isRowSelectable={(params) => {
                    return !params.row.deletedAt && !get(params, 'row.roles.SuperAdmin')
                }}
                filterModel={getFilterModel({companies})}
                disableColumnFilter
                getColumns={getColumns}
                paginationModel={paginationModel}
                apiToGetData={apiToGetData}
                dataConverter={convertData}
                buttons={buttons}
                checkboxSelection
                onSelectionModelChange={onSelectionModelChange}
            /> : <LoadingSpinner/>}
        </GridReactContextProvider>
    </div>
}