import {DataGrid} from "@mui/x-data-grid";
import './DataGridWrapper.scss'
import React, {useContext, useEffect, useRef, useState} from "react";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {SearchFilter} from "../GridFilters/SearchFilter/SerachFilter";
import {OrganizationFilter} from "../GridFilters/OrganizationFilter/OrganizationFilter";
import {GridReactContext} from "./GridContext";
import useDebounce from "../../Hooks/useDebounce";
import {CompanyFilter} from "../GridFilters/CompanyFilter/CompanyFilter";
import {get, isEqual, map} from "lodash";
import {Button} from "../../Components/Form/Button/Button";
import {buttonColors} from "../../Components/Pickers/InputTheme";
import {SignDocFilter} from "../GridFilters/SignDocFilter/SignDocFilter";
import IconButton from '@mui/material/IconButton';
import {MenuListComposition} from "../../Components/Form/ButtonMenu/ButtonMenu";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {i18n} from "../i18n/i18n";

const defaultPaginationModel = {
    page: 0,
    pageSize: 5,
};
const defaultFilterModel = {}
const defaultSortModel = []
export const DataGridWrapper = ({
                                    getColumns,
                                    paginationModel,
                                    apiToGetData,
                                    dataConverter,
                                    disableColumnFilter,
                                    filterModel,
                                    sortModel,
                                    buttons,
                                    checkboxSelection,
                                    onSelectionModelChange,
                                    selectionModel,
                                    isRowSelectable
                                }) => {
    const [innerPaginationModel, setInnerPaginationModel] = useState(paginationModel || defaultPaginationModel);
    const [innerFilterModel, setInnerFilterModel] = useState(filterModel || defaultFilterModel);
    const [innerSortModel, setInnerSortModel] = useState(sortModel || defaultSortModel)
    const [innerRowSelectionModel, setInnerRowSelectionModel] = useState(selectionModel || [])
    const [data, setData] = useState([])
    const [count, setCount] = useState()
    const [columns, setColumns] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const debouncedInnerFilterModel = useDebounce(innerFilterModel, 500)
    const debouncedInnerSortModel = useDebounce(innerSortModel, 500)
    const preventOnceFilter = useRef(true)
    const preventOnceSort = useRef(true)
    const preventOnceRefreshKey = useRef(true)
    const gridContext = useContext(GridReactContext)
    useEffect(() => {
        if (preventOnceFilter.current) {
            preventOnceFilter.current = false
            return
        }
        fetchServerData({
            paginationModel: innerPaginationModel,
            filterModel: debouncedInnerFilterModel,
            sortModel: innerSortModel
        })
    }, [debouncedInnerFilterModel])
    useEffect(() => {
        if (preventOnceSort.current) {
            preventOnceSort.current = false
            return
        }
        fetchServerData({
            paginationModel: innerPaginationModel,
            filterModel: innerFilterModel,
            sortModel: debouncedInnerSortModel
        })
    }, []);


    useEffect(() => {
        if (preventOnceRefreshKey.current) {
            preventOnceRefreshKey.current = false
            return
        }
        fetchServerData({
            paginationModel: innerPaginationModel,
            filterModel: innerFilterModel,
            sortModel: innerSortModel
        })
    }, [gridContext.refreshKey]);

    useEffect(() => {
        if(!isEqual(innerRowSelectionModel,gridContext.selectionModel)){
            setInnerRowSelectionModel(gridContext.selectionModel);
        }
    }, [gridContext.selectionModel]);


    const fetchServerData = async ({paginationModel, filterModel, sortModel}) => {
        setIsLoading(true)
        const res = await apiToGetData({paginationModel, filterModel, sortModel})
        const convertedData = dataConverter(res.data)
        setCount(res.count)
        setData(convertedData)
        setIsLoading(false)
    }

    useEffect(() => {
        const newPaginationModel = {
            ...innerPaginationModel
        }
        newPaginationModel.page = 0
        setInnerPaginationModel(newPaginationModel)
        getColumns({paginationModel: newPaginationModel, filterModel: innerFilterModel})
            .then((res) => {
                setColumns(res)
            })
        gridContext.updateFilterModel(innerFilterModel)
    }, [innerFilterModel]);

    useEffect(() => {
        if(!isEqual(innerRowSelectionModel, gridContext.selectionModel)){
            gridContext.updateSelectionModel(innerRowSelectionModel)
        }
    }, [innerRowSelectionModel]);
    useEffect(() => {
        gridContext.updateSortModel(innerSortModel)
    }, [innerSortModel]);
    useEffect(() => {
        gridContext.updatePaginationModel(innerPaginationModel)
    }, [innerPaginationModel]);

    const handlePaginationModelChange = (newPaginationModel) => {
        setInnerPaginationModel(newPaginationModel);
        fetchServerData({paginationModel: newPaginationModel, filterModel: innerFilterModel, sortModel: innerSortModel})
    }
    const handleSortModelChange = (newSortModel) => {
        setInnerSortModel(newSortModel);
        const newPaginationModel = {
            ...innerPaginationModel
        }
        newPaginationModel.page = 0
        setInnerPaginationModel(newPaginationModel)
        fetchServerData({paginationModel: newPaginationModel, filterModel: innerFilterModel, sortModel: newSortModel})
    }

    const handleSelectionModelChange = (newSelectionModel) => {
        setInnerRowSelectionModel(newSelectionModel);
        onSelectionModelChange({newSelectionModel})
    }
    return <div className="DataGrid">
        <div className="filterContainer">
            {filterModel?.showFilters?.search && <div className="searchFilterContainer filter">
                <SearchFilter filterModel={innerFilterModel} setFilterModel={setInnerFilterModel}/>
            </div>}
            {filterModel?.showFilters?.organizations && <div className="organizationFilterContainer filter">
                <OrganizationFilter filterModel={innerFilterModel} setFilterModel={setInnerFilterModel}/>
            </div>}
            {filterModel?.showFilters?.companies && <div className="organizationFilterContainer filter">
                <CompanyFilter filterModel={innerFilterModel} setFilterModel={setInnerFilterModel}
                               disabledObj={get(gridContext, 'filterDisabled.companies')}/>
            </div>}
            {filterModel?.showFilters?.signDocs && <div className="organizationFilterContainer filter">
                <SignDocFilter filterModel={innerFilterModel} setFilterModel={setInnerFilterModel}
                              disabledObj={get(gridContext, 'filterDisabled.signDocs')}/>
            </div>}
            {map(buttons, (button, index) => {
                const innerOnClick = async () => {
                    await button.onClick({
                        paginationModel: innerPaginationModel,
                        filterModel: innerFilterModel,
                        sortModel: innerSortModel,
                        selectionModel: innerRowSelectionModel
                    })
                }
                if (button.type === "IconButton") {
                    return <IconButton title={button.label} onClick={innerOnClick} size="large">
                        {button.icon}
                    </IconButton>

                }
                if(button.type === "A-Button") {
                    return <a className="a-button"
                              href={button.href}
                              target={button.target}>
                        <div className="iconWrapper">{button.icon}</div>
                        <div>{button.text}</div>
                    </a>
                }
                if (button.type === "MultipleOptionsButton") {
                    //wrap option actions
                    const optionActionWrapper = (action) => {
                        return async () => {
                            if(action && typeof action === "function"){
                                await action({
                                    paginationModel: innerPaginationModel,
                                    filterModel: innerFilterModel,
                                    sortModel: innerSortModel,
                                    selectionModel: innerRowSelectionModel
                                })
                            }
                        }
                    }
                    //go over options and convert the actions
                    const options = map(button.options, (option) => {
                        return {
                            ...option,
                            action: optionActionWrapper(option.action)
                        }
                    })
                    return <MenuListComposition
                        text={button.text}
                        icon={button.icon}
                        options={options}/>
                }
                if (button.type === "ComponentButton") {
                    const Component = button.component
                    return <Component/>
                }
                return <Button
                    icon={button.icon}
                    color={button.color || buttonColors.GREY}
                    onClick={innerOnClick}
                    text={button.text}
                />
            })}
        </div>
        <div className="tableContainer">
            {isLoading && <LoadingSpinner/>}
            <DataGrid
                sortingMode="server"
                disableColumnFilter={disableColumnFilter}
                rows={data}
                columns={columns || []}
                getRowId={(row) => row.id}
                paginationModel={innerPaginationModel}
                rowCount={count}
                disableRowSelectionOnClick
                paginationMode="server"
                pageSizeOptions={[]}
                onPaginationModelChange={handlePaginationModelChange}
                onSortModelChange={handleSortModelChange}
                checkboxSelection={checkboxSelection}
                onRowSelectionModelChange={handleSelectionModelChange}
                rowSelectionModel={innerRowSelectionModel}
                isRowSelectable={isRowSelectable}
            />
        </div>
    </div>
}