import {getCompanyPrefixAPI} from "../../../Services/api";
import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import {filter, find, get, keys, map} from 'lodash';
import {i18n} from "../../../Utils/i18n/i18n";
import './ImportUsers.scss'
import {UploadButton} from "../../Form/Button/UploadButton";
import ImportIcon from '@mui/icons-material/ImportExport';
import {buttonColors} from "../../Pickers/InputTheme";
import {importParametersFromFile} from "../../Reports/ReportsLib";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {FeatureFlags, MessageTypes} from "../../../Utils/Constants";
import {FormControlLabel, Switch} from "@mui/material";
import {MessageTypeSelectionButtons} from "../../Notifications/MessageTypeSelectionButtons/MessageTypeSelectionButtons";
import {CompanyReactContext} from "../../../contexts/CompanyContext";
import {useUserAdminOrgs} from "../../Hooks/UseUserAdminOrgs";
import {MultiSelectAutocomplete} from "../../Form/AutoComplete/AutoComplete";
import {ImportUsersReactContext} from "./ImportUsersModalContext";
import {UserReactContext} from "../../../contexts/UserContext";
import {LocalPopover, Popover} from "../../../Utils/Popover/Popover";

export const ImportUsers = ({
                                onlyAddOrgsWithoutRemoving = true
                            }) => {

    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({})
    const [selectedOrganizations, setSelectedOrganizations] = useState([])
    const [isProcessingFile, setIsProcessingFile] = useState(false)
    const [usersToUpload, setUsersToUpload] = useState()
    const [companyPrefix, setCompanyPrefix] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState()
    const [sendWelcomeMessage, setSendWelcomeMessage] = React.useState(false)
    const [selectedMessagingTypes, setSelectedMessagingTypes] = React.useState([MessageTypes.SMS])
    const companyContext = useContext(CompanyReactContext)
    const [processedFile, setProcessedFile] = useState()
    const importUsersContext = React.useContext(ImportUsersReactContext)
    const userContext = useContext(UserReactContext)

    const init = async () => {
        setIsLoading(true)
        await getCompanyPrefix()
        setIsLoading(false)
    }
    const getCompanyPrefix = async () => {
        const companyPrefix = await getCompanyPrefixAPI()
        setCompanyPrefix(companyPrefix)
    }

    useEffect(() => {
        let sendWelcomeMessageData;
        if (sendWelcomeMessage) {
            sendWelcomeMessageData = {
                messageTypes: selectedMessagingTypes
            }
        }
        const data = {
            organizationIds: map(selectedOrganizations, 'value'),
            users: map(keys(usersToUpload), key => usersToUpload[key]),
            sendWelcomeMessage: sendWelcomeMessageData,
            selectedMessagingTypes: selectedMessagingTypes,
            onlyAddOrgsWithoutRemoving,
            errors,
            isProcessingFile,
            isMissingSourceOfOrgs: isMissingSourceOfOrgs(),
            isThereTwoSourcesForOrgs: isThereTwoSourcesForOrgs()
        }
        importUsersContext.setUsersToUploadDataFunc(data)
    }, [usersToUpload, selectedOrganizations, sendWelcomeMessage, selectedMessagingTypes, onlyAddOrgsWithoutRemoving, errors, isProcessingFile]);


    const onSelectOrgsChange = async (selectedOrganizations) => {
        setIsLoading(true)
        setSelectedOrganizations(selectedOrganizations)
        setIsLoading(false)
    }

    const createUsersFromFile = async ({target}) => {
        setProcessedFile(target.files[0])
        target.value = ''
    }
    const processSelectedFile = async () => {
        setIsProcessingFile(true)
        try {
            const dataRes = await importParametersFromFile({
                file: processedFile,
                companyPrefix,
                selectedMessagingTypes,
                sendWelcomeMessage
            })
            setUsersToUpload(dataRes)
            setErrors({})
        } catch ({errors, data}) {
            setUsersToUpload(data)
            setErrors(errors)
        } finally {
            setIsProcessingFile(false)
        }

    }
    useEffect(() => {
        processSelectedFile()
    }, [processedFile, sendWelcomeMessage, selectedMessagingTypes]);

    useLayoutEffect(() => {
        if (!isOrgsLoading && adminOrganizationOptions) {
            init()
        }
    }, [isOrgsLoading]);


    const onSendWelcomeMessageChange = (e) => {
        setSendWelcomeMessage(e.target.checked)
    }
    const onSelectedMessagingTypeChange = (messagingType) => {
        //if it is the only one selected do not remove it

        if (selectedMessagingTypes.includes(messagingType) && selectedMessagingTypes.length > 1) {
            setSelectedMessagingTypes(selectedMessagingTypes.filter((type) => type !== messagingType))
        } else {
            let messagingTypes = [...selectedMessagingTypes, messagingType]
            //uniq array
            messagingTypes = messagingTypes.filter((value, index, self) => self.indexOf(value) === index)
            setSelectedMessagingTypes(messagingTypes)
        }
    }

    const getFilteredOrgs = ({query}) => {
        return filter(adminOrganizationOptions, org => org.text.toLowerCase().includes(query.toLowerCase()))
    }

    const isThereTwoSourcesForOrgs = () => {
        if (usersToUpload) {
            const userWithOrgs = find(usersToUpload, user => get(user, 'organizations.length'))
            if (userWithOrgs && selectedOrganizations && selectedOrganizations.length) {
                return true
            }
        }
        return false
    }
    const isMissingSourceOfOrgs = () => {
        if (usersToUpload) {
            const userWithoutOrgs = find(usersToUpload, user => !get(user, 'organizations.length'))
            if (userWithoutOrgs && (!selectedOrganizations || !selectedOrganizations.length)) {
                return true
            }
        }
        else{
            return true
        }
        if (onlyAddOrgsWithoutRemoving && !selectedOrganizations.length && !userContext.isManagingCompany()) {
            return true
        }
        return false
    }


    return <div className="importUsers">
        {(isLoading || importUsersContext.isUploading || isProcessingFile) && <LoadingSpinner/>}
        <div className="selectWrapper">
            <div className="selectTitle">
                <div>{onlyAddOrgsWithoutRemoving ? i18n.translate('importUsersModal.selectOrganizationsToAdd') : i18n.translate('importUsersModal.selectOrganization')}</div>
                <LocalPopover info={onlyAddOrgsWithoutRemoving ? i18n.translate('importUsersModal.newUsersWillBeCreatedAndOrganizationsWillBeAddedToAllUsers') : i18n.translate('importUsersModal.newUsersWillBeCreatedAndOrganizationsWillBeOverride')}></LocalPopover>
            </div>
            <div className="selectBoxRowWrapper">
                <div className="organizationsSelectWrapper">
                    {adminOrganizationOptions && adminOrganizationOptions.length ? <MultiSelectAutocomplete
                        selectAllText={i18n.translate('editAdminUserModal.selectAllOrgs')}
                        enableSelectAll
                        label={i18n.translate('editAdminUserModal.selectOrganizationalUnits')}
                        fetchOptions={getFilteredOrgs}
                        initialSelectedValues={selectedOrganizations}
                        onSelectionChange={onSelectOrgsChange}/> : ''}
                </div>
                <UploadButton color={buttonColors.GREY}
                              accept="txt/*"
                              disabled={importUsersContext.isUploading || isProcessingFile}
                              icon={<ImportIcon/>}
                              onInputChange={createUsersFromFile}
                              uniqId={"upload-1"}
                              text={i18n.translate('OrganizationsPage.importUsersFile')}
                />
            </div>
        </div>
        <div className="errorContainer">
            {map(keys(errors).slice(0, 2), (errorRowIndex, index) => {
                return <div key={`error-${index}`} className="error">
                    {i18n.translate('importUsersModal.errors.missingMandatoryField', {context: {row: parseInt(errorRowIndex) + 1}})} {map(keys(errors[errorRowIndex]), attr => `${i18n.translate(`importUsersModal.users.${attr}`)}`).join(', ')}
                </div>
            })}
            {isThereTwoSourcesForOrgs() && <div className="error">
                {onlyAddOrgsWithoutRemoving && !userContext.isManagingCompany() ? i18n.translate('importUsersModal.errors.removeOrgsFromFile') : i18n.translate('importUsersModal.errors.twoSourcesForOrgs')}
            </div>}
            {isMissingSourceOfOrgs() && <div className="error">
                {onlyAddOrgsWithoutRemoving && !userContext.isManagingCompany() ? i18n.translate('importUsersModal.errors.missingSourceForOrgsSelectFromMultiSelect') : i18n.translate('importUsersModal.errors.missingSourceForOrgs')}
            </div>}
        </div>
        <div className="welcomeMessageSend">
            {companyContext.featureFlags[FeatureFlags.SEND_WELCOME_MESSAGE] ? <FormControlLabel control={<Switch
                checked={sendWelcomeMessage}
                onChange={onSendWelcomeMessageChange}
            />} label={i18n.translate('createEditUserModal.sendWelcomeMessage')}/> : ''}

            {sendWelcomeMessage ? <MessageTypeSelectionButtons
                    // multiple={true}
                    onSelectedMessageTypeChange={onSelectedMessagingTypeChange}
                    selectedMessagingTypes={selectedMessagingTypes}
                    // excludeMessageTypes={[MessageTypes.WHATSAPP]}
                />
                : ''}
        </div>
        <div className="usersContainer">
            {usersToUpload ? <div className="tableTitles">
                <div className="countCol"></div>
                <div className="firstName">{i18n.translate('importUsersModal.users.firstName')}</div>
                <div className="lastName">{i18n.translate('importUsersModal.users.lastName')}</div>
                <div className="identifier">{i18n.translate('importUsersModal.users.identifier')}</div>
                <div className="phone">{i18n.translate('importUsersModal.users.phone')}</div>
                <div className="email">{i18n.translate('importUsersModal.users.email')}</div>
                <div className="organizations">{i18n.translate('importUsersModal.users.organizations')}</div>
            </div> : ''}
            {usersToUpload ? <div className="usersValues">
                {map(usersToUpload, (user, rowIndex) => {
                    return <div key={rowIndex} className={`userRow`}>
                        <div className="countCol">{parseInt(rowIndex) + 1}</div>
                        <div
                            className={`firstName ${get(errors, `[${rowIndex}].firstName`) ? 'error' : ''}`}>{user.firstName}</div>
                        <div
                            className={`lastName ${get(errors, `[${rowIndex}].lastName`) ? 'error' : ''}`}>{user.lastName}</div>
                        <div
                            className={`identifier ${get(errors, `[${rowIndex}].identifier`) ? 'error' : ''}`}>{user.identifier}</div>
                        <div
                            className={`phone ${get(errors, `[${rowIndex}].phone`) ? 'error' : ''}`}>{user.phone}</div>
                        <div
                            className={`email ${get(errors, `[${rowIndex}].email`) ? 'error' : ''}`}>{user.email}</div>
                        <div
                            className={`organizations ${get(errors, `[${rowIndex}].organizations`) ? 'error' : ''}`}>{user.organizations}</div>
                    </div>
                })}
            </div> : ''}
        </div>

    </div>
}