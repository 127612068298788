import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import './index.scss';
import {mainThemeJson} from "./mainTheme";

import {UserContextProvider} from "./contexts/UserContext";
import {APIHandler} from "./interceptors/APIHandler";
import {he} from 'date-fns/locale/he';
import {StyleSheetManager} from 'styled-components';
import rtlPlugin from 'stylis-plugin-rtl';
import {RefreshDataContextProvider} from "./contexts/RefreshDataContext";
import {CompanyContextProvider} from "./contexts/CompanyContext";
import {GoogleAnalytics} from "./Utils/GoogleAnalytics/GoogleAnalytics";
import {createRoot} from 'react-dom/client';
import {I18nReactContextProvider} from "./contexts/I18nContext";
import {CacheProviderWrapper} from "./Utils/CacheProviderWrapper/CacheProviderWrapper";

const mainTheme = createTheme(mainThemeJson);

const root = createRoot(document.getElementById('root'));

root.render(
    <UserContextProvider>
        <I18nReactContextProvider>
            <CacheProviderWrapper>
                <StyleSheetManager stylisPlugins={[rtlPlugin]}>
                    <ThemeProvider theme={mainTheme}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={he}>
                            <BrowserRouter>
                                <RefreshDataContextProvider>
                                    <CompanyContextProvider>
                                        <GoogleAnalytics/>
                                        <APIHandler/>
                                        <App/>
                                    </CompanyContextProvider>
                                </RefreshDataContextProvider>
                            </BrowserRouter>
                        </LocalizationProvider>
                    </ThemeProvider>
                </StyleSheetManager>
            </CacheProviderWrapper>
        </I18nReactContextProvider>
    </UserContextProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
