import { map } from 'lodash';

export const ruRU = {
    confirm: 'Подтвердить',
    login: {
        identifier: 'Идентификатор',
        password: 'Пароль',
        login: 'Войти',
        verificationCodeFromSMS: 'Код подтверждения, полученный по SMS',
        messageWasSentTo: (key, { last4digits }) =>
            `На номер телефона, заканчивающийся на ${last4digits}, был отправлен SMS-код`,
        validate: 'Проверить',
        resendCode: 'Отправить код повторно',
        sendCode: 'Отправить код подтверждения',
        helloX: (key, { name }) => `Здравствуйте, ${name}`,
    },
    changePassword: {
        currentPassword: 'Текущий пароль',
        password: 'Новый пароль',
        repeatPassword: 'Новый пароль',
        changePassword: 'Сменить пароль',
    },
    logout: 'Выйти',
    menuItems: {
        logout: 'Выйти',
        changePassword: 'Сменить пароль',
        adminPanel: 'Админ-панель',
        home: 'Главная страница',
        myOrgs: 'Мои организационные единицы',
        learningCenter: 'Учебный центр',
        users: 'Пользователи',
        reports: 'Отчеты',
        settings: 'Настройки',
        learningManagement: 'Управление обучением',
        filesManagement: 'Управление файлами',
        knowledgeCenter: 'Центр знаний',
        superAdmin: {
            courses: 'Управление курсами',
            customers: 'Управление клиентами',
            organizations: 'Управление организационными единицами',
            users: 'Управление пользователями',
        },
    },
    languages: {
        label: 'Выберите язык',
        languageKeys: {
            heIL: 'עברית',
            enUS: 'English',
            arIL: 'العربية',
            ruRU: 'Русский'
        }
    },
    learningCenter: {
        title: 'Мой учебный центр',
        subTitle: 'Задачи и статус их выполнения',
        coursesToDo: 'Курсы, которые еще не завершены',
        coursesCompleted: 'Курсы, которые я завершил',
        noCoursesToDo: 'Нет незавершенных курсов',
        noCoursesCompleted: 'Нет завершенных курсов',
        signDocsToDo: 'Документы для подписания',
        noSignDocsToDo: 'Нет документов для подписания',
        noSignDocsCompleted: 'Нет подписанных мной документов',
        signDocsCompleted: 'Документы, которые я подписал',
        noOpenTasks: 'Нет открытых задач',
        tabs: {
            myCourses: 'Мои курсы',
            mySignDocs: 'Документы для подписания',
            myTasks: 'Открытые задачи',
        },
    },
    settingsPage: {
        title: 'Настройки',
        subTitle: 'Настройте систему',
        subTitleBold: 'под потребности вашей компании',
        buttons: {
            updateLogo: 'Обновить логотип',
            updateOrgName: 'Обновить названия организационных единиц',
            updateNotes: 'Обновить заметки для сотрудников',
        },
        editNotesModal: {
            title: 'Редактировать заметки для сотрудников',
        },
        editOrganizationsNames: 'Редактировать названия организационных единиц',
        createNote: 'Создать новую заметку',
        newNoteTitle: 'Новая заметка',
        SMSQuota: {
            title: 'Лимит сообщений',
            quota: 'Приобретенные сообщения',
            sent: (key, { sent, quota }) =>
                `Обратите внимание, что на данный момент отправлено ${sent} сообщений и у вас осталось ${quota} сообщений для дальнейшего использования`,
            lastSMSWasSentOn: (key, { date }) =>
                `Последние сообщения были отправлены из системы ${date}`,
        },
        generalSettings: {
            title: 'Общее',
        },
        reminders: {
            title: 'Напоминания',
            course: 'Курс',
            signDoc: 'Документ для подписания',
            watchVideo: 'Посмотреть видео',
            canvaPresentation: 'Просмотр Canva',
        },
        admins: {
            title: 'Администраторы',
            subTitle: 'Управление и настройки',
        },
    },
    HomePage: {
        courseLegend: {
            completed: 'Завершено',
            inProgress: 'В процессе',
            notStarted: 'Не начато',
        },
    },
    home: {
        title: 'Доступные для вас курсы',
        faq: 'Часто задаваемые вопросы',
        welcome: 'Добро пожаловать',
        learningSubtitle: 'в вашу личную учебную среду для',
        userChartLabels: {
            finishedCourses: 'Завершенные курсы',
            inProgressCourses: 'Курсы в процессе',
            notStartedCourses: 'Курсы не начаты',
            label: 'Прогресс курсов',
        },
        superAdmin: 'Интерфейс администратора системы',
    },
    faq: {
        question1: 'Могу ли я в любое время вернуться к курсам?',
        answer1:
            'Да. Вы можете обучаться в своем темпе из любого места и в любое время, со смартфона или компьютера (мы рекомендуем использовать Google Chrome). Обратите внимание, что если вы начали курс, его необходимо завершить на том же устройстве.',
        question2: 'Что делать, когда я завершу курсы?',
        answer2:
            'Когда вы закончите курс, нажмите кнопку «Завершить» и подтвердите, что вы прошли курс. Убедитесь, что не осталось открытых задач.',
        question3: 'Нужна помощь?',
        answer3:
            'Не стесняйтесь связаться с нами по телефону 054-3493062 или по электронной почте office@nakarmedic.co.il',
    },
    gender: {
        male: 'Мужской',
        female: 'Женский',
    },
    OrganizationsPage: {
        title: 'Мои организационные единицы',
        subTitle: 'Управление и настройки',
        organizations: 'Организационные единицы, которые я администрирую',
        importUsersFile: 'Импорт файла пользователей',
        editLogo: 'Редактировать логотип',
        dropLogoHere: 'Перетащите логотип сюда',
        currentLogo: 'Текущий логотип',
        changeForAllMyOrganizations:
            'Изменить для всех организационных единиц, которыми я управляю',
        logoWillBeChangedForAllOrgs:
            '**Логотип будет изменен для всех организационных единиц под вашим управлением',
    },
    UsersPage: {
        title: 'Пользователи',
        subTitle: 'Управление и настройки',
        userRowCourseMenu: {
            buttons: {
                addToCourse: 'Зарегистрировать пользователя',
                removeFromCourse: 'Удалить регистрацию пользователя',
            },
        },
        userRowSignDocMenu: {
            buttons: {
                addToSignDoc: 'Зарегистрировать пользователя',
                removeFromSignDoc: 'Удалить регистрацию',
                markCompleted: 'Отметить как выполнено',
            },
        },
    },
    organizationUserTable: {
        headers: {
            name: 'Имя',
            permissions: 'Разрешения',
            identifier: 'Идентификатор',
        },
    },
    userType: {
        ADMIN: 'Администратор',
        USER: 'Пользователь',
    },
    note: {
        title: 'Заголовок',
        content: 'Содержание',
        delete: 'Удалить заметку',
        deleteNoteConfirmation: {
            title: 'Удалить заметку',
            message: 'Вы уверены, что хотите удалить заметку?',
            noteName: 'Название заметки',
            deleteNote: 'Удалить заметку',
        },
    },
    organization: {
        organizationName: 'Название организационной единицы',
    },
    courseCard: {
        youAreDone: 'Вы завершили курс',
        finishBy: (key, { finishByDate }) => `Завершить до ${finishByDate}`,
        youAreDoneWatchAgain: 'Посмотреть еще раз',
        goToCourse: 'Перейти к курсу',
    },
    courseProgress: {
        notStarted: 'Не начато',
        inProgress: 'В процессе',
        finished: 'Завершено!',
    },
    ReportsPage: {
        title: 'Отчеты',
        subTitle: 'Просмотр статуса завершения сотрудниками',
        courseLegend: {
            completed: 'Завершено',
            inProgress: 'В процессе',
            notStarted: 'Не начато',
        },
        coursesStats: {
            title: 'Статус завершения каждого курса',
        },
        signDocStats: {
            title: {
                course: 'Статус завершения каждого курса',
                signDocs: 'Статус подписания каждого документа',
            },
            graphs: {
                usersPerformanceNumbers: (key, { taskCount = 0 }) =>
                    `Количество пользователей по статусу выполнения ${
                        taskCount ? `(${taskCount} задач)` : ''
                    }`,
                usersPerformancePercent: (key, { taskCount = 0 }) =>
                    `Процент пользователей по статусу выполнения ${
                        taskCount ? `(${taskCount} задач)` : ''
                    }`,
                performanceNumberByOrg: (key, { orgsCount = 0 }) =>
                    `Количество завершений в организационной единице ${
                        orgsCount ? `(${orgsCount} организационных единиц)` : ''
                    }`,
                performancePercentByOrg: (key, { orgsCount = 0 }) =>
                    `Процент завершения в организационной единице ${
                        orgsCount ? `(${orgsCount} организационных единиц)` : ''
                    }`,
                signDocsCount: ' Документов для подписания',
                activeCourseCount: 'Активные учебные элементы',
                usersCount: 'Пользователи',
                usersWithActiveTasks: 'Пользователи с активной задачей',
                allSignDocsOfACompanyCount: 'Учебные и тренировочные элементы',
                info: {
                    activeCourseCount:
                        'Общее количество учебных элементов с будущей датой окончания (курсы, документы для подписания и задачи просмотра)',
                    usersCount: 'Общее количество пользователей',
                    usersWithActiveTasks:
                        'Общее количество пользователей, у которых есть хотя бы одна активная задача',
                    allSignDocsOfACompanyCount:
                        'Общее количество учебных элементов (курсы, документы для подписания и задачи просмотра)',
                    performanceNumberByOrg:
                        'Общее количество выполненных задач из всех накопленных задач\n (Накопленные задачи = количество учебных и тренировочных элементов, умноженное на количество пользователей, назначенных на каждый элемент)',
                    performancePercentByOrg:
                        'Общее количество завершенных задач в каждой организационной единице',
                },
            },
        },
        tabs: {
            dashboard: 'Главная панель',
            courses: 'Курсы',
            signDocs: 'Документы для подписания',
            generatedReports: 'Сформированные отчеты',
        },
    },
    courseProgressMgmnt: {
        notStarted: 'Не начато',
        inProgress: 'В процессе',
        finished: 'Завершено',
        completedOn: 'Дата подписания: ',
        completedOnCourse: 'Дата завершения: ',
        by: 'от ',
        manual: 'Вручную',
        finishBy: 'Завершить до: ',
    },
    LearningManagementPage: {
        title: 'Управление обучением',
        subTitle: 'Добавлять, редактировать и удалять',
        addCourse: 'Добавить курс',
        editCourse: 'Редактировать курс',
        deleteCourse: 'Удалить курс',
        courseName: 'Название курса',
        courseDescription: 'Описание курса',
        courseImage: 'Изображение курса',
        createKnowledgeSource: 'Создать источник знаний',
        courseCard: {
            editCourse: 'Редактировать курс',
        },
        signDocCard: {
            editSignDoc: {
                READ_AND_SIGN_DOC_PDF: 'Редактировать документ',
                WATCH_AND_SIGN_YOUTUBE_LINK: 'Редактировать задачу просмотра',
                WATCH_AND_SIGN_VIMEO_LINK: 'Редактировать задачу просмотра',
                WATCH_AND_SIGN_VIDEO_FILE: 'Редактировать задачу просмотра',
                REGULAR_COURSE: 'Редактировать курс',
                CANVA_PRESENTATION: 'Редактировать презентацию',
            },
            deleteModal: {
                title: 'Удалить документ',
                text: 'Вы уверены, что хотите удалить этот документ? Это действие необратимо',
                confirm: 'Удалить документ',
                signDocName: 'Название документа',
            },
        },
        users: {
            buttons: {
                downloadCsv: 'Экспорт в CSV',
                downloadCsvForOrganization:
                    'Экспорт пользователей для выбранной организационной единицы',
                downloadCsvForAllOrganizations:
                    'Экспорт пользователей для всех организационных единиц, которыми я управляю',
            },
        },
        courseManagement: 'Управление курсами',
        signDocManagement: 'Управление документами',
        noSignDocs: 'Нет документов для отображения',
        tabs: {
            signDocs: 'Документы для подписания',
            courses: 'Курсы',
            users: 'Пользователи',
            knowledgeCenter: 'Центр знаний',
        },
    },
    editCourseModal: {
        title: 'Редактировать курс',
        courseName: 'Название курса',
        courseDescription: 'Описание курса',
        courseImage: 'Изображение курса',
        save: 'Сохранить изменения',
        edit: {
            courseName: 'Название курса',
            courseDescription: 'Описание курса',
            dateEditNotification:
                'Вы можете установить единый срок завершения для всех пользователей, зарегистрированных на курс во всех управляемых вами организационных единицах. Любые изменения здесь переопределят индивидуальные настройки пользователей.',
        },
        dateModal: {
            title: 'Установить дату окончания',
            timeAndTimezone: 'До 00:00 в часовом поясе: ',
        },
    },
    editUserCourseModal: {
        title: (key, { userName, courseName }) =>
            `Установить дату окончания курса "${courseName}" для пользователя "${userName}"`,
        dateEditNotification:
            'Вы можете установить дату окончания для пользователя. Это изменение будет действовать, пока вы не измените дату окончания для всей организационной единицы.',
    },
    AppSideMenu: {
        welcome: 'Добро пожаловать',
    },
    superAdmin: {
        editFFModal: {
            modalTitle: 'Редактировать функции компании',
            editFeatures: 'Редактировать функции',
            featureFlags: {
                automationFF: 'Автоматизация',
                smsFF: 'SMS',
                whatsappFF: 'WhatsApp',
                emailFF: 'Email',
                signDocsFF: 'Задачи',
                sendWelcomeMessageFF: 'Отправлять приветственные сообщения новым пользователям',
                signDocsReadAndSignDocPdfFF: 'Подписывать PDF',
                signDocsWatchAndSignYoutubeLinkFF: 'Подтверждать просмотр Youtube',
                signDocsWatchAndSignVimeoLinkFF: 'Подтверждать просмотр Vimeo',
                signDocsWatchAndSignVideoFileFF: 'Подтверждать просмотр видеофайла',
                signDocCanvaPresentationFF: 'Презентация Canva',
                regularCourseFF: 'Курсы',
                notifyNewTaskToUserFF: 'Уведомлять пользователя о новой задаче',
                knowledgeCenterFF: 'Центр знаний',
                orgAdminMgmtFF: 'Управление правами администратора',
            },
        },
        allUsersTable: {
            columns: {
                firstName: 'Имя',
                lastName: 'Фамилия',
                email: 'Email',
                phone: 'Телефон',
                roles: 'Роли',
                identifier: 'Идентификатор',
                organization: 'Организационная единица',
                id: 'ID',
                companyName: 'Компания',
                yes: 'Да',
                no: 'Нет',
                isSuperAdmin: 'Супер-админ',
                isCompanyAdmin: 'Админ компании',
                isDeleted: 'Удален',
                edit: 'Редактировать',
                permanentlyDelete: 'Удалить безвозвратно',
                loginUrl: 'Ссылка для входа',
            },
            buttons: {
                downloadCsvForOrganization:
                    'Скачать Excel-файл для организационной единицы',
                downloadCsvForAllOrganizations:
                    'Скачать Excel-файлы для всех организационных единиц, которыми я управляю',
                downloadCsvForOrganizationSuccess:
                    'Ваши файлы вскоре будут доступны для загрузки в разделе «Меню -> Управление файлами»',
                deleteUsers: 'Удалить пользователей',
                edit: 'Редактировать',
            },
        },
        allOrgsTable: {
            columns: {
                orgName: 'Название организационной единицы',
                taxIdentifier: 'Идентификатор',
                companyId: 'ID компании',
                orgAddress: 'Адрес',
                orgContactInfo: 'Контактная информация',
                id: 'ID',
                companyName: 'Название компании',
                delete: 'Удалить',
            },
            deleteOrg: 'Удалить организационную единицу',
            deleteOrgTitle: 'Удалить организационную единицу: ',
            deleteOrgText: 'Вы уверены, что хотите удалить эту организационную единицу',
            deleteOrgConfirm: 'Удалить организационную единицу',
        },
        createNewCourse: {
            title: 'Создать новый курс',
            docName: 'Название курса',
            courseDescription: 'Описание курса',
            create: 'Создать курс',
            dropZipHere:
                'Перетащите zip-файл курса сюда или нажмите, чтобы выбрать файл',
            modalTitle: 'Создать новый курс',
            organizationsTable: {
                orgName: 'Название организационной единицы',
                addOrganization: 'Добавить курс в организационную единицу',
                removeOrganization: 'Удалить курс из организационной единицы',
                onlyOneCompanyPerCourse:
                    'Курс можно добавить только к одной компании, поэтому сейчас нельзя изменить выбор компании',
            },
        },
        createNewCustomer: {
            modalTitle: 'Создать нового клиента',
            companyName: 'Название компании',
            userPrefix:
                'Префикс пользователя (уникален для каждой компании – только на английском)',
            identifier: 'Идентификатор',
            contactEmail: 'Контактный email',
            contactName: 'Контактное лицо',
            contactPhone: 'Телефон контактного лица',
            userCreationTitle: 'Создать первого пользователя компании',
            detailsTitle: 'Заполните данные компании',
            userPassword: 'Пароль пользователя (только английский)',
            userIdentifier: 'Идентификатор пользователя (только английский)',
            createCompany: 'Создать компанию',
            userNameNoteNoPrefix:
                '*Необходимо указать префикс пользователя прежде, чем вводить имя пользователя',
            userNameNote: (key, { companyUsersPrefix, userIdentifier }) =>
                `*Фактическое имя пользователя, которое будет создано: ${companyUsersPrefix}-${
                    userIdentifier || '"User Identifier"'
                }`,
            userPrefixError: 'Префикс пользователя должен быть только на английском',
            userFirstName: 'Имя',
            userLastName: 'Фамилия',
            contactFirstName: 'Имя контактного лица',
            contactLastName: 'Фамилия контактного лица',
        },
        editCourse: {
            courseName: 'Название курса',
            docName: 'Название курса',
            courseDescription: 'Описание курса',
            description: 'Описание курса',
            dropZipHere:
                'Перетащите zip-файл курса сюда или нажмите, чтобы выбрать файл',
            dropZipNote:
                '**Обратите внимание, что вы находитесь в режиме редактирования. Если zip-файл уже загружен для этого курса, загрузка нового файла перезапишет существующий**',
            saveChanges: 'Сохранить изменения',
            modalTitle: 'Редактировать курс',
        },
        editCustomer: {
            modalTitle: 'Редактировать клиента',
            adminUserDetails: 'Данные главного пользователя',
            detailsTitle: 'Редактировать данные компании',
            organizationsTitle: 'Организационные единицы клиента',
            addOrganizations: 'Добавить организационные единицы клиенту',
            addOrganizationModal: {
                organizationsList: 'Список организационных единиц',
                useCommaSeparatedValues:
                    'Введите значения, разделенные запятыми, например: "Финансы, Бухгалтерия, Разработка ПО"',
                title: 'Добавить организационные единицы клиенту',
            },
        },
        coursesTable: {
            columns: {
                courseName: 'Название задачи',
                description: 'Описание задачи',
                edit: 'Редактировать',
                type: 'Тип задачи',
                editCourse: 'Редактировать курс',
                companyName: 'Название компании',
                remove: 'Удалить',
            },
        },
        customersTable: {
            columns: {
                companyName: 'Название компании',
                taxIdentifier: 'Идентификатор',
                edit: 'Редактировать',
                editCompany: 'Редактировать компанию',
                smsQuota: 'Лимит сообщений',
                featureFlags: 'Функции',
                storageQuotaMB: 'Хранилище (MB)',
                editQuota: 'Редактировать квоты',
            },
            quota: {
                editQuota: 'Редактировать квоты',
                editQuotaTitle: 'Редактировать квоты для компании',
                editCredits: 'Редактировать кредиты',
                MessagesCreditsToEdit: 'Кредиты сообщений',
                storageQuotaToEdit: 'Хранилище в MB',
            },
        },
    },
    dataGrid: {
        filters: {
            search: {
                input: 'Поиск',
            },
        },
        filterLabels: {
            course: 'Курс',
            organization: 'Организационная единица',
            company: 'Компания',
            signDoc: 'Задачи',
        },
    },
    importUsersPage: {
        downloadExample: 'Скачать пример файла для создания пользователей',
        downloadUsersAsCSV: 'Скачать пользователей в формате CSV',
    },
    importUsersModal: {
        title: 'Импорт/редактирование пользователей и единиц',
        tabs: {
            importUsers: {
                title: 'Импорт пользователей и добавление единиц',
            },
            reorganizeUsers: {
                title: 'Импорт пользователей и изменение единиц',
            },
        },
        manyUsersMessage:
            'Похоже, что в файле много пользователей, импорт может занять несколько минут. После нажатия «Создать» просьба не закрывать окно до окончания загрузки, а затем подождать еще несколько минут, пока изменения отобразятся в системе.',
        users: {
            firstName: 'Имя',
            lastName: 'Фамилия',
            identifier: 'Идентификатор',
            phone: 'Телефон',
            email: 'Email',
            organizations: 'Организационные единицы',
        },
        createUsers: 'Создать/редактировать пользователей',
        selectOrganization:
            'Выберите организационные единицы, для которых будут созданы пользователи',
        selectOrganizationsToAdd: 'Выберите организационные единицы для добавления',
        organization: 'Организационная единица',
        newUsersWillBeCreatedAndOrganizationsWillBeAddedToAllUsers:
            'Эти организационные единицы будут добавлены всем пользователям, найденным в прилагаемом файле. Пользователи, которых нет в системе, будут созданы, а существующие будут обновлены.',
        newUsersWillBeCreatedAndOrganizationsWillBeOverride:
            'Организационные единицы будут изменены для всех пользователей в прилагаемом файле (пользователи будут удалены из тех единиц, которые не выбраны). Не существующие пользователи будут созданы, а существующие будут обновлены.',
        errors: {
            missingMandatoryField: (key, { row }) =>
                `*Отсутствуют обязательные поля в строке ${row}:`,
            twoSourcesForOrgs:
                'Нельзя выбирать организационные единицы из двух источников. Удалите организационные единицы из импортируемого файла или из выбора выше',
            removeOrgsFromFile: 'Удалите организационные единицы из импортируемого файла',
            missingSourceForOrgs:
                'Не выбраны организационные единицы для пользователей. Выберите единицы вверху страницы или добавьте их в шаблон импорта.',
            missingSourceForOrgsSelectFromMultiSelect:
                'Не выбраны организационные единицы для пользователей. Выберите их в списке выше.',
        },
    },
    uploadBox: {
        uploading: 'Идет загрузка файла...',
        uploaded: 'Файл успешно загружен',
        uploadFailed:
            'Файл недействителен. Проверьте размер и тип файла, чтобы убедиться, что не превышен допустимый лимит.',
        maxFileSize: (key, { maxFileSize }) =>
            `Максимально допустимый размер файла — ${maxFileSize}`,
        onlyFileTypes: (key, { fileTypes }) =>
            `Допустимые типы файлов для загрузки: ${map(fileTypes, (fileType) => `${fileType}`).join(', ')}`,
    },
    completedCoursePage: {
        title: 'Завершение курса',
        subTitle: 'Пожалуйста, подтвердите, что вы завершили курс',
        buttons: {
            confirmCourseCompletion: 'Подтверждаю, что завершил(а) курс',
        },
        finishedSuccessfully: 'Вы успешно завершили курс',
        oops: 'Упс...',
        youGotLost: 'Кажется, вы заблудились, используйте меню для навигации',
        courseCompletedSuccessfullyPopup: 'Вы успешно завершили курс',
    },
    errors: {
        clientError: 'Ошибка в предоставленных данных',
        serverError: 'Ошибка запроса к серверу',
        generalServerError:
            'Общая ошибка запроса к серверу. Попробуйте перезагрузить страницу. Если проблема повторяется, обратитесь в техническую поддержку.',
        notFound: 'Ресурс не найден',
        forbidden: 'У вас нет прав доступа к этому ресурсу',
        privateRouteUserNotLoggedIn:
            'Пользователь не авторизован, поэтому доступ к содержимому невозможен. Пожалуйста, войдите в систему и попробуйте снова.',
    },
    alertDialog: {
        ok: 'ОК',
        cancel: 'Отмена',
    },
    createEditUserModal: {
        createUser: 'Создать пользователя',
        titleCreate: 'Создать/обновить пользователя',
        titleEdit: 'Редактировать пользователя',
        selectOrganization: 'Выберите организационную единицу',
        firstName: 'Имя',
        lastName: 'Фамилия',
        identifier: 'Идентификатор',
        phone: 'Телефон',
        email: 'Email',
        editUser: 'Редактировать пользователя',
        deleteUserTitle: 'Удалить пользователя',
        deleteUserText: 'Вы уверены, что хотите удалить пользователя',
        deleteOrgConfirm: 'Удалить пользователя',
        deleteUserButton: 'Удалить пользователя',
        userIsCompanyAdmin: 'Администратор компании',
        mfaRequired: 'Требуется двухфакторная аутентификация',
        sendWelcomeMessage: 'Отправить приветственное сообщение при первой авторизации',
        save: 'Сохранить/обновить пользователя',
        userWillBeEditedIfPhoneExists:
            'Если этот номер телефона уже связан с пользователем в компании, этот пользователь и его организационные единицы будут обновлены',
    },
    allUsersTable: {
        columns: {
            firstName: 'Имя',
            lastName: 'Фамилия',
            email: 'Email',
            phone: 'Телефон',
            roles: 'Роли',
            identifier: 'Идентификатор',
            organization: 'Организационная единица',
            id: 'ID',
            edit: 'Редактировать',
            delete: 'Удалить',
            progress: 'Прогресс',
            notRegistered: 'Не зарегистрирован на курс',
        },
    },
    removeCourseFromCompanyCol: {
        deleteCourseButton: 'Удалить курс',
        removeCourseTitle: 'Удалить курс',
        removeCourseText: 'Вы уверены, что хотите удалить курс',
        removeCourseConfirm: 'Удалить курс',
    },
    CourseReminderNotificationButton: {
        alerts: {
            reminderSent: 'Напоминания отправляются!',
            reminderNotSent: 'Произошла ошибка при отправке напоминания',
            sentMessages: 'Сообщения отправлены',
            notSentMessages: 'Сообщения не были отправлены',
            notSentMessagesInfo:
                '* Существует две возможные причины, по которым не все сообщения были отправлены: отсутствие контактной информации или неправильный номер телефона',
            sendToUsersWhoDidNotReceiveNotification:
                'Отправить напоминание пользователям, которые еще его не получили',
            sendToAllUsers:
                'Отправить напоминание всем пользователям курса, которые еще не завершили',
            error: 'Ошибка',
            company_has_no_sms_quota:
                'В компании недостаточно SMS-кредитов, обратитесь в службу поддержки для приобретения пакета сообщений',
        },
    },
    FilesManagementPage: {
        title: 'Управление файлами',
        subTitle: 'Просмотр, добавление и редактирование',
        fileManagementTable: {
            columns: {
                fileName: 'Ссылка для загрузки',
                presentationName: 'Отображаемое имя',
                isReady: 'Готов для скачивания',
                companyName: 'Компания',
                organizationNames: 'Организационные единицы',
                date: 'Дата создания',
                createdByUser: 'Создан пользователем',
            },
            download: 'Скачать',
            inProgress: 'Создается',
            ready: 'Готов к загрузке',
            allOrganizations: 'Все организационные единицы',
        },
    },
    SMSNotificationButton: {
        title: 'Отправить напоминание',
        subTitle: 'Немедленно',
    },
    sendSMSModal: {
        title: 'Кому напомним?',
        sms: 'SMS',
        whatsapp: 'WhatsApp',
        email: 'Email',
        selectMessageDeliveryWay: 'Сообщения будут отправлены через:',
        sendToOptions: {
            all: 'Пользователи, не завершившие конкретную задачу',
            onlyNotNotified: 'Все, кто не завершил курс и не получал напоминание',
            allWithAnyOpenTasks: 'Пользователи с открытыми задачами',
            pleaseSelect: 'Пожалуйста, выберите группу, которой хотите отправить напоминание',
            label: 'Кому?',
        },
        taskOptions: {
            label: 'Какое задание?',
            pleaseSelect: 'Пожалуйста, выберите курс, по которому хотите отправить напоминание',
        },
        organizationOptions: {
            label: 'Какая организационная единица?',
            pleaseSelect: 'Пожалуйста, выберите организационную единицу, по которой хотите отправить напоминание',
        },
        smsStatusInformation: {
            statusTitle: 'Отлично!',
            statusUserToNotify: 'Итого мы отправим напоминание ',
            bySMS: 'через SMS',
            byWhatsApp: 'через WhatsApp',
            byEmail: 'через Email',
            statusCompanyQuota: 'Учтите, что после отправки у вас останется ',
            SMSForUse: 'сообщений для дальнейшего использования',
            employees: 'сотрудникам',
            theyWillReceiveItAsFollowing: 'которые получат сообщения следующим способом',
        },
        error: {
            noUsersToSendOnlyNotNotified:
                'Нет пользователей для отправки; все, кто не завершил курс, уже получили напоминание',
            notEnoughSMS:
                'У вас недостаточно SMS-кредитов, свяжитесь с поддержкой для покупки пакета сообщений',
            noUsersInCourse:
                'Нет пользователей для отправки; все пользователи в этом курсе уже завершили его',
            youAreLeftWithQuota: 'У вас осталось',
            youNeed: 'а всего необходимо',
            toCompleteTheProcess: 'для завершения процесса',
        },
        send: 'Отправить',
    },
    SADeleteUsersModal: {
        title: 'Удалить пользователей',
        firstName: 'Имя',
        lastName: 'Фамилия',
        email: 'Email',
        phone: 'Телефон',
        identifier: 'Идентификатор',
        message: (key, { userCount }) =>
            `Вы уверены, что хотите удалить следующих ${userCount} пользователей?`,
        deleteUsers: 'Удалить пользователей',
    },
    SARemoveUser: {
        permanentlyDeleteUser: 'Удалить безвозвратно',
        deleteUserTitle: 'Безвозвратное удаление пользователя',
        deleteUserText:
            'Вы уверены, что хотите безвозвратно удалить пользователя',
        deleteOrgConfirm: 'Удалить пользователя безвозвратно',
    },
    automations: {
        title: 'Автоматизация',
        subTitle: 'Управление и настройки',
        addAutomation: 'Добавить автоматизацию',
        editAutomation: 'Редактировать автоматизацию',
        deleteAutomation: 'Удалить автоматизацию',
        automationName: 'Название автоматизации',
        automationDescription: 'Описание автоматизации',
        automationImage: 'Изображение автоматизации',
        automationCard: {
            editAutomation: 'Редактировать автоматизацию',
        },
        notifications: {
            title: 'Создать автоматизацию',
            subTitle: 'для планирования напоминаний',
        },
        createAutomationModal: {
            weWillSendNotificationTo: 'Мы вышлем напоминание всем, кто не завершил курс ',
            allOrganizations: 'Все организационные единицы',
            organizationOptions: {
                label: 'Какая организационная единица?',
                pleaseSelect:
                    'Пожалуйста, выберите организационную единицу, которой вы хотите отправить напоминание',
                error: 'Необходимо выбрать организационную единицу для отправки напоминания',
            },
            taskOptions: {
                label: 'Какое задание?',
                pleaseSelect: 'Пожалуйста, выберите задание, для которого вы хотите отправить напоминание',
                error: 'Необходимо выбрать курс для отправки напоминания',
            },
            selectHourLabel: 'В котором часу?',
            selectHourError: 'Необходимо выбрать время отправки напоминания',
            howManyDaysBefore: 'За сколько дней до окончания?',
            howManyDaysBeforeError:
                'Необходимо указать, за сколько дней до окончания отправить напоминание',
            nameLabel: 'Название автоматизации',
            descriptionLabel: 'Описание автоматизации',
            create: 'Создать автоматизацию',
        },
    },
    automationsTable: {
        columns: {
            automationName: 'Название автоматизации',
            description: 'Описание автоматизации',
            organization: 'Организационная единица',
            task: 'Задача',
            when: 'Когда?',
            status: 'Статус',
            how: 'Как отправляется?',
            delete: 'Удалить',
            edit: 'Редактировать',
        },
        status: {
            SCHEDULED: 'Запланировано',
            COMPLETED: 'Отправлено',
            sentAndNotSent: (key, { sent, notSent }) =>
                `Отправлено: ${sent}, не отправлено: ${notSent}`,
        },
        sendType: {
            SMS: 'SMS',
            EMAIL: 'Email',
            WHATSAPP: 'WhatsApp',
        },
        atHour: 'в',
        daysBefore: 'дней до даты окончания курса',
    },
    allOrganizations: 'Все организационные единицы',
    allSignDocs: 'Все задачи',
    confirmationModal: {
        confirm: 'Подтвердить',
        cancel: 'Отменить',
    },
    deleteAutomationModal: {
        title: 'Удалить автоматизацию',
        message: 'Вы уверены, что хотите удалить эту автоматизацию?',
        deleteAutomation: 'Удалить автоматизацию',
        automationName: 'Название автоматизации',
    },
    newDocSign: {
        buttonTitle: 'Создать новую задачу',
        modal: {
            title: 'Создать новый документ',
            docNameLabel: 'Название документа',
            docDescriptionLabel: 'Описание документа',
            createDocument: 'Создать документ',
            dropSignDocHere:
                'Перетащите файл документа сюда или нажмите, чтобы выбрать файл',
            selectSignDocType: 'Тип',
            linkUrl: 'Ссылка',
            youtubeLinkNotAllowed: 'Нельзя ввести ссылку Youtube',
            vimeoLinkNotAllowed: 'Нельзя ввести ссылку Vimeo',
            invalidLink: 'Недействительная ссылка',
            notifyUsersOfNewTask: 'Отправить сообщение пользователям о новой задаче',
            forceCompletion: 'Обязательно для выполнения',
            nonForceCompletion: 'Необязательно для выполнения',
            noDate: 'Без даты окончания',
        },
    },
    signDocProgress: {
        notStarted: {
            READ_AND_SIGN_DOC_PDF: 'Я не читал',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'Я не смотрел',
            WATCH_AND_SIGN_VIMEO_LINK: 'Я не смотрел',
            WATCH_AND_SIGN_VIDEO_FILE: 'Я не смотрел',
            REGULAR_COURSE: 'Я не начинал',
            CANVA_PRESENTATION: 'Я не смотрел',
        },
        inProgress: {
            READ_AND_SIGN_DOC_PDF: 'Я прочитал',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'Я посмотрел',
            WATCH_AND_SIGN_VIMEO_LINK: 'Я посмотрел',
            WATCH_AND_SIGN_VIDEO_FILE: 'Я посмотрел',
            REGULAR_COURSE: 'Я начал',
            CANVA_PRESENTATION: 'Я посмотрел',
        },
        finished: {
            READ_AND_SIGN_DOC_PDF: 'Подписано!',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'Подписано!',
            WATCH_AND_SIGN_VIMEO_LINK: 'Подписано!',
            WATCH_AND_SIGN_VIDEO_FILE: 'Подписано!',
            REGULAR_COURSE: 'Завершено!',
            CANVA_PRESENTATION: 'Подписано!',
        },
    },
    signDocProgressMgmnt: {
        notStarted: 'Не открыто',
        inProgress: 'Открыто',
        finished: 'Подписано',
    },
    signDocPage: {
        title: {
            read: {
                sign: 'Подтверждение прочтения документа',
                noSign: 'Прочтение документа',
                knowledgeOnly: 'Прочтение документа',
            },
            watch: {
                sign: 'Подтверждение просмотра видео',
                noSign: 'Просмотр видео',
                knowledgeOnly: 'Просмотр видео',
            },
        },
        subTitle: {
            read: {
                sign: 'Подтвердите и подпишите, что вы прочитали документ',
                noSign: 'Перечитать после подписания',
            },
            watch: {
                sign: 'Подтвердите и подпишите, что вы посмотрели видео',
                noSign: 'Пересмотреть после подписания',
            },
        },
        signatureTitle: {
            read: 'Подпишитесь, чтобы подтвердить, что вы прочли документ',
            watch: 'Подпишитесь, чтобы подтвердить, что вы посмотрели видео',
        },
        signatureSubtitle: {
            read: 'Для подтверждения прочтения документа, подпишитесь здесь',
            watch: 'Для подтверждения просмотра видео, подпишитесь здесь',
        },
        signatureSubtitleNotCompleted: {
            read: 'После прочтения появятся поле для подписи и кнопка подтверждения',
            watch: 'После просмотра появятся поле для подписи и кнопка подтверждения',
        },
        iApproveReadingTheDoc: {
            read: 'Я прочитал документ!',
            watch: 'Я посмотрел видео!',
        },
        clear: 'Очистить подпись',
    },
    signDocCard: {
        youAreDone: {
            READ_AND_SIGN_DOC_PDF: 'Вы подписали документ',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'Вы посмотрели видео',
            WATCH_AND_SIGN_VIMEO_LINK: 'Вы посмотрели видео',
            WATCH_AND_SIGN_VIDEO_FILE: 'Вы посмотрели видео',
            REGULAR_COURSE: 'Вы завершили курс',
            CANVA_PRESENTATION: 'Вы посмотрели презентацию',
        },
        finishBy: {
            READ_AND_SIGN_DOC_PDF: (key, { finishByDate }) =>
                `Завершить до ${finishByDate}`,
            WATCH_AND_SIGN_YOUTUBE_LINK: (key, { finishByDate }) =>
                `Завершить до ${finishByDate}`,
            WATCH_AND_SIGN_VIMEO_LINK: (key, { finishByDate }) =>
                `Завершить до ${finishByDate}`,
            WATCH_AND_SIGN_VIDEO_FILE: (key, { finishByDate }) =>
                `Завершить до ${finishByDate}`,
            REGULAR_COURSE: (key, { finishByDate }) =>
                `Завершить до ${finishByDate}`,
            CANVA_PRESENTATION: (key, { finishByDate }) =>
                `Завершить до ${finishByDate}`,
        },
        youAreDoneWatchAgain: {
            READ_AND_SIGN_DOC_PDF: 'Прочитать снова',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'Посмотреть снова',
            WATCH_AND_SIGN_VIMEO_LINK: 'Посмотреть снова',
            WATCH_AND_SIGN_VIDEO_FILE: 'Посмотреть снова',
            REGULAR_COURSE: 'Посмотреть снова',
            CANVA_PRESENTATION: 'Посмотреть снова',
        },
        goToCourse: {
            READ_AND_SIGN_DOC_PDF: 'Перейти к чтению',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'Перейти к просмотру',
            WATCH_AND_SIGN_VIMEO_LINK: 'Перейти к просмотру',
            WATCH_AND_SIGN_VIDEO_FILE: 'Перейти к просмотру',
            REGULAR_COURSE: 'Перейти к курсу',
            CANVA_PRESENTATION: 'Перейти к просмотру',
        },
    },
    Errors_409: {
        storage_quota_error: (
            key,
            { bytesCompanyStorageQuota, bytesCompanyUsedStorage, bytesNewUsedStorage }
        ) => {
            const leftQuotaMB = (
                (bytesCompanyStorageQuota - bytesCompanyUsedStorage) /
                (1024 * 1024)
            ).toFixed(2) + ' MB';
            const bytesNewUsedStorageMB = (
                bytesNewUsedStorage /
                (1024 * 1024)
            ).toFixed(2) + ' MB';
            const bytesCompanyStorageQuotaMB = (
                bytesCompanyStorageQuota /
                (1024 * 1024)
            ).toFixed(2) + ' MB';
            return `Недостаточно доступного места в хранилище. Попробуйте удалить ненужные файлы или обратитесь к администратору системы. У вас осталось ${leftQuotaMB} из ${bytesCompanyStorageQuotaMB}, а размер файла, который вы пытаетесь загрузить, составляет ${bytesNewUsedStorageMB}.`;
        },
    },
    signDocTypes: {
        READ_AND_SIGN_DOC_PDF: 'PDF-документ для подписания',
        WATCH_AND_SIGN_YOUTUBE_LINK: 'Ссылка Youtube для просмотра',
        WATCH_AND_SIGN_VIMEO_LINK: 'Ссылка VIMEO для просмотра',
        WATCH_AND_SIGN_VIDEO_FILE: 'Видеофайл для просмотра',
        REGULAR_COURSE: 'Курс',
        CANVA_PRESENTATION: 'Презентация Canva для просмотра',
    },
    signDocTypesNotForCompletion: {
        READ_AND_SIGN_DOC_PDF: 'PDF-документ',
        WATCH_AND_SIGN_YOUTUBE_LINK: 'Ссылка Youtube',
        WATCH_AND_SIGN_VIMEO_LINK: 'Ссылка VIMEO',
        WATCH_AND_SIGN_VIDEO_FILE: 'Видеофайл',
        REGULAR_COURSE: 'Курс',
        CANVA_PRESENTATION: 'Презентация Canva',
    },
    managementCourseList: {
        signDocButtonTitle: 'Создать задачу просмотра',
    },
    isCompanyAdmin: {
        makeAdmin: {
            title: 'Назначить администратором компании',
            message: 'Вы уверены, что хотите назначить этого пользователя администратором компании?',
            confirm: 'Назначить администратором компании',
            button: 'Назначить администратором компании',
        },
        removeAdmin: {
            title: 'Снять права администратора компании',
            message: 'Вы уверены, что хотите снять права администратора компании с этого пользователя?',
            confirm: 'Снять права администратора компании',
            button: 'Снять права администратора компании',
        },
        userName: 'Имя пользователя',
    },
    general: {
        saveChanges: 'Сохранить изменения',
        close: 'Закрыть',
        cancel: 'Отмена',
    },
    knowledgeCenter: {
        title: 'Центр знаний',
        subTitle: 'Вся информация в одном месте',
    },
    adminUsersTable: {
        columns: {
            firstName: 'Имя',
            lastName: 'Фамилия',
            email: 'Email',
            phone: 'Телефон',
            roles: 'Роли',
            identifier: 'Идентификатор',
            managerOfOrganizations: 'Администратор в организационных единицах',
            id: 'ID',
            edit: 'Редактировать',
            isCompanyAdmin: 'Админ компании',
            isCompanyAdminYes: 'Да',
            isCompanyAdminNo: 'Нет',
        },
        addAdminUser: 'Добавить администратора',
    },
    editAdminUserModal: {
        title: 'Назначить администратора',
        titleEdit: 'Редактировать администратора',
        sendWelcomeMessage: 'Отправить приветственное сообщение администратору',
        save: 'Сохранить изменения',
        selectOrganizationalUnits: 'Поиск организационных единиц',
        searchUser: 'Поиск пользователя',
        isCompanyAdmin: 'Админ компании',
        companyAdminWillBeAdminOfAllOrgs:
            'Админ компании будет администратором всех организационных единиц',
        selectAllOrgs: 'Выбрать все организационные единицы',
    },
    autoComplete: {
        search: 'Поиск',
        selectAll: 'Выбрать все',
    },
    UserIconMenu: {
        settings: 'Настройки',
    }
};
