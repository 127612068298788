import './Setup.scss';
import {ImportUserContextProvider} from "../../Users/ImportUsersModal/ImportUsersModalContext";
import {ImportUsersModal} from "../../Users/ImportUsersModal/ImportUsersModal";
import React from "react";
import {Navigate} from "react-router-dom";

export const Setup = () => {
    const [importUsersModalOpen, setImportUsersModalOpen] = React.useState(true)
    const [navigateToLearningManagement, setNavigateToLearningManagement] = React.useState(false)
    const [navigateToGeneralSettings, setNavigateToGeneralSettings] = React.useState(false)
    const openImportUsersModal = () => {
        setImportUsersModalOpen(true)
    }
    const closeImportUsersModal = () => {
        setImportUsersModalOpen(false)
        setNavigateToGeneralSettings(true)
    }

    const onSuccessCreateUsers = () => {
        closeImportUsersModal()
        setNavigateToLearningManagement(true)
    }
    if (navigateToLearningManagement) {
        return <Navigate to={{pathname: `/learningManagement/users`}} replace={true}/>
    }
    if(navigateToGeneralSettings){
        return <Navigate to={{pathname: `/settings/general`}} replace={true}/>
    }
    return <div className="Setup">

        <ImportUserContextProvider>
            {importUsersModalOpen && <ImportUsersModal closeImportUsersModal={closeImportUsersModal}
                                                       importUserModalOpen={importUsersModalOpen}
                                                       onSuccessCreateUsers={onSuccessCreateUsers}/>}
        </ImportUserContextProvider>
    </div>
}